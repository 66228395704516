import React, { useContext, useState } from 'react';
import './CSS/ShopCategory.css';
import { ShopContext } from '../Context/ShopContext';
import Item from '../Components/Item/Item';

const categorySettings = {
  tiles: {
    sizeOptions: ["6.5x20", "7.5x30", "10x20", "10x30", "15x15", "15x90", "19.8x22.8 hex", "20x20", "20x60", "20x120"],
    finishOptions: ["Matt", "Glossy", "Lappato"],
    typeOptions: ["Floor & Wall", "Wall", "Outdoor", "Pattern", "Wood Effect"],
    thicknessOptions: ["8", "9", "10", "10.5"],
    materialOptions: ["Porcelain", "Red Ceramic", "White Ceramic"],
    brandOptions: ["Baldocer", "Cristacer", "KTL", "Red", "STN", "Style", "Tau", "Vista Original"],
    rectifiedOptions: ["Rectified", "Non-Rectified"],
    colourOptions: ['White', 'Beige', 'Grey', 'Cream', 'Brown', 'Black', 'Blue', 'Green', 'Red', 'Yellow', 'Pink']
  },
  laminate: {
    sizeOptions: ["20x140", "20x130", "14x64", "10x60", "24x202", "16x140"],
    finishOptions: ["Matt", "Glossy"],
    typeOptions: ["Straight Plank", "Herringbone", "XXL Plank", "Thin Plank"],
    thicknessOptions: ["7", "8", "9", "10", "12"],
    materialOptions: ["AC3", "AC4", "AC5", "AC6"],
    brandOptions: ["Krono Original", "Kronopol", "Shamrock", "Egger", "Supreme", "Balterio", "Floor2go", "Alsa", "Classen", "Vista Original"],
    warrantyOptions: ["15", "20", "25", "30", "35"],
    additionalOptions: {
      "Water_Protection": ["Suitable", "Not Suitable"]
    },
    colourOptions: ['Dark', 'Light', 'Medium', 'Grey', 'White', 'Brown', 'Multi-Coloured']
  },
  "real-wood": {
    sizeOptions: ["12x60"],
    finishOptions: ["Matt"],
    typeOptions: ["Real Wood"],
    thicknessOptions: ["14"],
    materialOptions: ["Oak"],
    wear_layerOptions: ["0.6"],
    brandOptions: ["Vista Original"],
    colourOptions: ['Dark', 'Light', 'Medium', 'Grey', 'White', 'Brown', 'Multi-Coloured']
  }
};

const ShopCategory = (props) => {
  const { category } = props;
  const normalizedCategory = category.toLowerCase();
  const settings = categorySettings[normalizedCategory] || categorySettings.tiles;
  const { all_product } = useContext(ShopContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortCriteria, setSortCriteria] = useState({
    size: '',
    finish: '',
    sub_category: '',
    thickness: '',
    material: '',
    brand: '',
    rectified: '',
    ac_rating: '',
    underfloor_heat: '',
    water_protection: '',
    warranty: '',
    additional: {},
    colour: [],
    minPrice: 0,
    maxPrice: 130
  });

  const [showSorting, setShowSorting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const matchesSearchTerm = (item) => {
    const searchLower = searchTerm.toLowerCase();
    return item.name.toLowerCase().includes(searchLower) ||
           item.size.toLowerCase().includes(searchLower) ||
           item.finish.toLowerCase().includes(searchLower) ||
           item.sub_category?.toLowerCase().includes(searchLower);
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setSortCriteria(prev => {
      const newColours = prev.colour.includes(value) ? prev.colour.filter(c => c !== value) : [...prev.colour, value];
      return { ...prev, colour: newColours };
    });
  };

  const handleSortChange = (e) => {
    const { name, value } = e.target;
    setSortCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setSortCriteria(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const renderTilesControls = () => {
    return (
      <>
        <label htmlFor="size">Size:</label>
        <select name="size" id="size" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.sizeOptions.map(size => (
            <option key={size} value={size}>{size}cm</option>
          ))}
        </select>

        <label htmlFor="finish">Finish:</label>
        <select name="finish" id="finish" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.finishOptions.map(finish => (
            <option key={finish} value={finish}>{finish}</option>
          ))}
        </select>

        <label htmlFor="sub_category">Type:</label>
        <select name="sub_category" id="sub_category" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.typeOptions.map(sub_category => (
            <option key={sub_category} value={sub_category}>{sub_category}</option>
          ))}
        </select>

        <label htmlFor="thickness">Thickness:</label>
        <select name="thickness" id="thickness" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.thicknessOptions.map(thickness => (
            <option key={thickness} value={thickness}>{thickness}mm</option>
          ))}
        </select>

        <label htmlFor="material">Material:</label>
        <select name="material" id="material" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.materialOptions.map(material => (
            <option key={material} value={material}>{material}</option>
          ))}
        </select>

        <label htmlFor="brand">Brand:</label>
        <select name="brand" id="brand" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.brandOptions.map(brand => (
            <option key={brand} value={brand}>{brand}</option>
          ))}
        </select>

        <label htmlFor="rectified">Rectified:</label>
        <select name="rectified" id="rectified" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {categorySettings.tiles.rectifiedOptions.map(rectified => (
            <option key={rectified} value={rectified}>{rectified}</option>
          ))}
        </select>

        <label htmlFor="colour">Colours:</label>
        <div className="checkbox-container">
          {categorySettings.tiles.colourOptions.map(color => (
            <label key={color}>
              <input
                type="checkbox"
                name="colour"
                value={color}
                checked={sortCriteria.colour.includes(color)}
                onChange={handleColorChange}
                className='input-right'
              />
              {color}
            </label>
          ))}
        </div>
      </>
    );
  };

  const renderLaminateControls = () => {
  const laminate = categorySettings.laminate;
  return (
    <>
      <label htmlFor="size">Size:</label>
      <select name="size" id="size" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.sizeOptions?.map(size => (
          <option key={size} value={size}>{size}cm</option>
        ))}
      </select>

      <label htmlFor="finish">Finish:</label>
      <select name="finish" id="finish" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.finishOptions?.map(finish => (
          <option key={finish} value={finish}>{finish}</option>
        ))}
      </select>

      <label htmlFor="sub_category">Type:</label>
      <select name="sub_category" id="sub_category" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.typeOptions?.map(sub_category => (
          <option key={sub_category} value={sub_category}>{sub_category}</option>
        ))}
      </select>

      <label htmlFor="thickness">Thickness:</label>
      <select name="thickness" id="thickness" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.thicknessOptions?.map(thickness => (
          <option key={thickness} value={thickness}>{thickness}mm</option>
        ))}
      </select>

      <label htmlFor="material">Material:</label>
      <select name="material" id="material" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.materialOptions?.map(material => (
          <option key={material} value={material}>{material}</option>
        ))}
      </select>

      <label htmlFor="brand">Brand:</label>
      <select name="brand" id="brand" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.brandOptions?.map(brand => (
          <option key={brand} value={brand}>{brand}</option>
        ))}
      </select>

      <label htmlFor="warranty">Warranty:</label>
      <select name="warranty" id="warranty" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.warrantyOptions?.map(warranty => (
          <option key={warranty} value={warranty}>{warranty} Years</option>
        ))}
      </select>

      <label htmlFor="water_protection">Water Protection:</label>
      <select name="water_protection" id="water_protection" onChange={handleSortChange} className="shop-select">
        <option value="">All</option>
        {laminate.additionalOptions['Water_Protection']?.map(waterProtection => (
          <option key={waterProtection} value={waterProtection}>{waterProtection}</option>
        ))}
      </select>

      <label htmlFor="colour">Colours:</label>
      <div className="checkbox-container">
        {laminate.colourOptions?.map(color => (
          <label key={color}>
            <input
              type="checkbox"
              name="colour"
              value={color}
              checked={sortCriteria.colour.includes(color)}
              onChange={handleColorChange}
              className='input-right'
            />
            {color}
          </label>
        ))}
      </div>
    </>
  );
};

  
  const renderRealWoodControls = () => {
    const realWood = categorySettings["real-wood"];
    console.log('Rendering real-wood controls:', realWood);  // Debugging output

    if (!realWood) {
      return <p>No real wood options available.</p>;  // Fallback message
    }

    return (
      <>
        <label htmlFor="size">Size:</label>
        <select name="size" id="size" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.sizeOptions?.map(size => (
            <option key={size} value={size}>{size}cm</option>
          ))}
        </select>

        <label htmlFor="finish">Finish:</label>
        <select name="finish" id="finish" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.finishOptions?.map(finish => (
            <option key={finish} value={finish}>{finish}</option>
          ))}
        </select>

        <label htmlFor="sub_category">Type:</label>
        <select name="sub_category" id="sub_category" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.typeOptions?.map(sub_category => (
            <option key={sub_category} value={sub_category}>{sub_category}</option>
          ))}
        </select>

        <label htmlFor="thickness">Thickness:</label>
        <select name="thickness" id="thickness" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.thicknessOptions?.map(thickness => (
            <option key={thickness} value={thickness}>{thickness}mm</option>
          ))}
        </select>

        <label htmlFor="material">Material:</label>
        <select name="material" id="material" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.materialOptions?.map(material => (
            <option key={material} value={material}>{material}</option>
          ))}
        </select>

        <label htmlFor="wear_layer">Wear Layer:</label>
        <select name="wear_layer" id="wear_layer" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.wear_layerOptions?.map(wearLayer => (
            <option key={wearLayer} value={wearLayer}>{wearLayer}mm</option>
          ))}
        </select>

        <label htmlFor="brand">Brand:</label>
        <select name="brand" id="brand" onChange={handleSortChange} className="shop-select">
          <option value="">All</option>
          {realWood.brandOptions?.map(brand => (
            <option key={brand} value={brand}>{brand}</option>
          ))}
        </select>

        <label htmlFor="colour">Colours:</label>
        <div className="checkbox-container">
          {realWood.colourOptions?.map(color => (
            <label key={color}>
              <input
                type="checkbox"
                name="colour"
                value={color}
                checked={sortCriteria.colour.includes(color)}
                onChange={handleColorChange}
                className='input-right'
              />
              {color}
            </label>
          ))}
        </div>
      </>
    );
  };

  const filteredProducts = all_product.filter(item => {
    const itemCategoryNormalized = item.category?.toLowerCase() || '';
    console.log("Filtering for category:", normalizedCategory);
    console.log("Item category (normalized):", itemCategoryNormalized);

    const matchesThickness = sortCriteria.thickness ? item.thickness && item.thickness.toString() === sortCriteria.thickness : true;
    const matchesSubCategory = sortCriteria.sub_category ? (item.sub_category || "Unknown").toLowerCase() === sortCriteria.sub_category.toLowerCase() : true;
    const matchesPriceRange = item.new_price >= sortCriteria.minPrice && item.new_price <= sortCriteria.maxPrice;

    return (itemCategoryNormalized === normalizedCategory) &&
      (!sortCriteria.colour.length || sortCriteria.colour.some(color => item.colour && item.colour.includes(color))) &&
      (sortCriteria.size ? item.size === sortCriteria.size : true) &&
      (sortCriteria.finish ? item.finish === sortCriteria.finish : true) &&
      matchesSubCategory &&
      matchesThickness &&
      (sortCriteria.material ? item.material === sortCriteria.material : true) &&
      (sortCriteria.brand ? item.brand === sortCriteria.brand : true) &&
      (sortCriteria.rectified ? item.rectified === sortCriteria.rectified : true) &&
      (sortCriteria.ac_rating ? item.ac_rating === sortCriteria.ac_rating : true) &&
      (sortCriteria.warranty ? item.warranty === sortCriteria.warranty : true) &&
      (sortCriteria.water_protection ? item.water_protection === sortCriteria.water_protection : true) &&
      (sortCriteria.underfloor_heat ? item.underfloor_heat === sortCriteria.underfloor_heat : true) &&
      matchesSearchTerm(item) &&
      matchesPriceRange;
  });

  const displayedProducts = filteredProducts.slice(0, currentPage * itemsPerPage);

  return (
    <div className='shop-category'>
      <div className="sidebar">
        <div className="search-container">
          <input
            type="text"
            placeholder={`Search ${props.category || 'all categories'}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <button onClick={handleClearSearch} className="clear-button">Clear</button>
        </div>

        <div className="price-inputs">
          <div className="price-input-group">
            <label htmlFor="minPrice">Min Price:</label>
            <input
              type="number"
              name="minPrice"
              min="0"
              max="130"
              value={sortCriteria.minPrice}
              onChange={handlePriceChange}
              className="price-input1"
            />
          </div>
          <div className="price-input-group">
            <label htmlFor="maxPrice">Max Price:</label>
            <input
              type="number"
              name="maxPrice"
              min="0"
              max="130"
              value={sortCriteria.maxPrice}
              onChange={handlePriceChange}
              className="price-input2"
            />
          </div>
        </div>

        <button
          className="toggle-button"
          onClick={() => setShowSorting(!showSorting)}
        >
          {showSorting ? 'Hide Filters' : 'Show Filters'}
        </button>
        <div className={`sorting-controls ${showSorting ? 'show' : ''}`}>
          {normalizedCategory === 'tiles' ? renderTilesControls() :
           normalizedCategory === 'laminate' ? renderLaminateControls() :
           normalizedCategory === 'real-wood' ? renderRealWoodControls() : null}
        </div>
      </div>
      <div className="product-area">
        <div className="product-count">
          <p><span>Showing {displayedProducts.length} out of {filteredProducts.length} products</span></p>
        </div>
        <div className="shopcategory-products">
          {displayedProducts.map((item, i) => (
            <Item key={i} id={item.id} name={item.name} size={item.size} image={item.images[0]} new_price={item.new_price} />
          ))}
        </div>
        {displayedProducts.length < filteredProducts.length && (
          <button className="load-more-button" onClick={() => setCurrentPage(currentPage + 1)}>
            Load More
          </button>
        )}
      </div>
    </div>
  );
}

export default ShopCategory;

