import React, { useState } from 'react';
import './Gallery.css';
import kitchen_island from '../Assets/GalleryFolder/kitchen_island_grey.webp';
import hallway_grey from '../Assets/GalleryFolder/hallway_grey.webp';
import WoodEffect_bathroom from '../Assets/GalleryFolder/Wood_Effect_bathroom.jpg';
import livingroom_walnut from '../Assets/GalleryFolder/livingroom_walnut.webp';
import bedroom_grey from '../Assets/GalleryFolder/bedroom_grey.webp';
import stairs_laminateCarpet from '../Assets/GalleryFolder/stairs_carpet_laminate.webp';
import kitchen_chair from '../Assets/GalleryFolder/kitchen_chairs.webp';
import hallway_herringbone from '../Assets/GalleryFolder/hallway_herringbone.webp';
import livingroom_beige from '../Assets/GalleryFolder/livingroom_beige.webp';
import bathroom_copper from '../Assets/GalleryFolder/bathroom_copper.webp';
import bedroom_grey2 from '../Assets/GalleryFolder/bedroom_grey2.webp';
import stairs_laminate from '../Assets/GalleryFolder/stairs_laminate.webp';
import kitchen_bodo from '../Assets/GalleryFolder/kitchen_bodo.webp';
import hallway_oak from '../Assets/GalleryFolder/hallway_oak.webp';
import bathroom_channel from '../Assets/GalleryFolder/bathroom_chennel.webp';
import livingroom_beige2 from '../Assets/GalleryFolder/livingroom_beige2.webp';
import bedroom_walnut from '../Assets/GalleryFolder/bedroom_walnut.jpg';
import stairs_herringbone from '../Assets/GalleryFolder/stairs_herringbone.jpg';
import kitchen_splashback from '../Assets/GalleryFolder/kitchen_splashback.webp';
import hallway_greige from '../Assets/GalleryFolder/hallway_greige.jpg';
import livingroom_deer from '../Assets/GalleryFolder/livingroom_deer.webp';
import bathroom_half from '../Assets/GalleryFolder/bathroom_half.webp';
import bedroom_walnut2 from '../Assets/GalleryFolder/bedroom_walnut2.jpg';
import stairs_turn from '../Assets/GalleryFolder/stairs_turn.jpg';
import kitchen_camden from '../Assets/GalleryFolder/kitchen_camden.webp';
import hallway_tile from '../Assets/GalleryFolder/hallway_tile.jpg';
import bathroom_howard from '../Assets/GalleryFolder/bathroom_howard.jpg';
import livingroom_walnut2 from '../Assets/GalleryFolder/livingroom_walnut2.jpg';
import bedroom_bay from '../Assets/GalleryFolder/bedroom_bay.jpg';
import stairs_carpet_laminate2 from '../Assets/GalleryFolder/stairs_carpet_laminate2.jpg'; 
import alsaherringbone1 from '../Assets/GalleryFolder/alsaherringbone1.jpg';
import alsaherringbone2 from '../Assets/GalleryFolder/alsaherringbone2.jpg';
import amazing from '../Assets/GalleryFolder/amazing.jpg'; 
import bathroomwithcirclarmirror from '../Assets/GalleryFolder/bathroomwithcirclarmirror.jpg';
import crash_beige from '../Assets/GalleryFolder/crash_beige.jpg'; 
import kitchen_marble from '../Assets/GalleryFolder/kitchen_marble.JPG'; 
import Herringbone_tile_shower from '../Assets/GalleryFolder/Herringbone_tile_shower.jpg';
import image5 from '../Assets/GalleryFolder/image5.jpeg';
import image7 from '../Assets/GalleryFolder/image7.jpeg';
import IMG_0518 from '../Assets/GalleryFolder/IMG_0518.JPG';
import IMG_4434 from '../Assets/GalleryFolder/IMG_4434.JPEG';
import IMG_4441 from '../Assets/GalleryFolder/IMG_4441.JPEG';
import IMG_4443 from '../Assets/GalleryFolder/IMG_4443.JPEG';
import IMG_5361 from '../Assets/GalleryFolder/IMG-5361.jpg';
import IMG_5364 from '../Assets/GalleryFolder/IMG-5364.jpg';
import IMG_5588 from '../Assets/GalleryFolder/IMG-5588.JPG';
import IMG_5589 from '../Assets/GalleryFolder/IMG-5589.JPG';
import kitchen_white_marble from '../Assets/GalleryFolder/Kitchen white Marble Gloss with kitchen island.webp';
import small_bathroom_beige_greige from '../Assets/GalleryFolder/Small Bathroom beige greige.webp';
import tilesandwoodfloorstore_2 from '../Assets/GalleryFolder/tilesandwoodfloorstore (2).jpg';
import tilesandwoodfloorstore_3 from '../Assets/GalleryFolder/tilesandwoodfloorstore (3).jpg';
import tilesandwoodfloorstore_4 from '../Assets/GalleryFolder/tilesandwoodfloorstore (4).jpg';
import tilesandwoodfloorstore_9 from '../Assets/GalleryFolder/tilesandwoodfloorstore (9).jpg';
import tilesandwoodfloorstore_10 from '../Assets/GalleryFolder/tilesandwoodfloorstore (10).jpg';
import tilesandwoodfloorstore_12 from '../Assets/GalleryFolder/tilesandwoodfloorstore (12).jpg';
import tilesandwoodfloorstore_16 from '../Assets/GalleryFolder/tilesandwoodfloorstore (16).jpg';
import tilesandwoodfloorstore_17 from '../Assets/GalleryFolder/tilesandwoodfloorstore (17).jpg';
import tilesandwoodfloorstore_18 from '../Assets/GalleryFolder/tilesandwoodfloorstore (18).jpg';
import tilesandwoodfloorstore_19 from '../Assets/GalleryFolder/tilesandwoodfloorstore (19).jpg';
import tilesandwoodfloorstore_20 from '../Assets/GalleryFolder/tilesandwoodfloorstore (20).jpg';
import tilesandwoodfloorstore_22 from '../Assets/GalleryFolder/tilesandwoodfloorstore (22).jpg';
import tilesandwoodfloorstore_24 from '../Assets/GalleryFolder/tilesandwoodfloorstore (24).jpg';
import tilesandwoodfloorstore_25 from '../Assets/GalleryFolder/tilesandwoodfloorstore (25).jpg';
import tilesandwoodfloorstore_26 from '../Assets/GalleryFolder/tilesandwoodfloorstore (26).jpg';
import tilesandwoodfloorstore_27 from '../Assets/GalleryFolder/tilesandwoodfloorstore (27).jpg';
import tilesandwoodfloorstore_28 from '../Assets/GalleryFolder/tilesandwoodfloorstore (28).jpg';
import tilesandwoodfloorstore_29 from '../Assets/GalleryFolder/tilesandwoodfloorstore (29).jpg';
import tilesandwoodfloorstore_30 from '../Assets/GalleryFolder/tilesandwoodfloorstore (30).jpg';
import tilesandwoodfloorstore_32 from '../Assets/GalleryFolder/tilesandwoodfloorstore (32).jpg';
import tilesandwoodfloorstore_33 from '../Assets/GalleryFolder/tilesandwoodfloorstore (33).jpg';
import tilesandwoodfloorstore_35 from '../Assets/GalleryFolder/tilesandwoodfloorstore (35).jpg';
import tilesandwoodfloorstore_38 from '../Assets/GalleryFolder/tilesandwoodfloorstore (38).jpg';
import tilesandwoodfloorstore_39 from '../Assets/GalleryFolder/tilesandwoodfloorstore (39).jpg';
import tilesandwoodfloorstore_40 from '../Assets/GalleryFolder/tilesandwoodfloorstore (40).jpg';
import tilesandwoodfloorstore_41 from '../Assets/GalleryFolder/tilesandwoodfloorstore (41).jpg';
import tilesandwoodfloorstore_42 from '../Assets/GalleryFolder/tilesandwoodfloorstore (42).jpg';
import tilesandwoodfloorstore_44 from '../Assets/GalleryFolder/tilesandwoodfloorstore (44).jpg';
import tilesandwoodfloorstore_45 from '../Assets/GalleryFolder/tilesandwoodfloorstore (45).jpg';
import tilesandwoodfloorstore_46 from '../Assets/GalleryFolder/tilesandwoodfloorstore (46).jpg';
import tilesandwoodfloorstore_48 from '../Assets/GalleryFolder/tilesandwoodfloorstore (48).jpg';
import tilesandwoodfloorstore_49 from '../Assets/GalleryFolder/tilesandwoodfloorstore (49).jpg';
import tilesandwoodfloorstore_50 from '../Assets/GalleryFolder/tilesandwoodfloorstore (50).jpg';
import tilesandwoodfloorstore_52 from '../Assets/GalleryFolder/tilesandwoodfloorstore (52).jpg';
import tilesandwoodfloorstore_53 from '../Assets/GalleryFolder/tilesandwoodfloorstore (53).jpg';
import tilesandwoodfloorstore_54 from '../Assets/GalleryFolder/tilesandwoodfloorstore (54).jpg';
import tilesandwoodfloorstore_56 from '../Assets/GalleryFolder/tilesandwoodfloorstore (56).jpg';
import tilesandwoodfloorstore_58 from '../Assets/GalleryFolder/tilesandwoodfloorstore (58).jpg';
import tilesandwoodfloorstore_60 from '../Assets/GalleryFolder/tilesandwoodfloorstore (60).jpg';
import tilesandwoodfloorstore_61 from '../Assets/GalleryFolder/tilesandwoodfloorstore (61).jpg';
import tilesandwoodfloorstore_62 from '../Assets/GalleryFolder/tilesandwoodfloorstore (62).jpg';
import tilesandwoodfloorstore_63 from '../Assets/GalleryFolder/tilesandwoodfloorstore (63).jpg';
import tilesandwoodfloorstore_64 from '../Assets/GalleryFolder/tilesandwoodfloorstore (64).jpg';
import tilesandwoodfloorstore_65 from '../Assets/GalleryFolder/tilesandwoodfloorstore (65).jpg';
import tilesandwoodfloorstore_67 from '../Assets/GalleryFolder/tilesandwoodfloorstore (67).jpg';


const photoData = [
  { id: 1, category: 'Kitchens', url: kitchen_island, alt: 'Modern kitchen with grey 120x60cm porcelain tiles, featuring a central island and blue chairs'},
  { id: 2, category: 'Hallways', url: hallway_grey, alt: 'Grey laminate hallway with a vibrant red door, creating a striking entryway'},
  { id: 3, category: 'Bathrooms', url: WoodEffect_bathroom, alt: 'Wood-effect porcelain tiles on bathroom walls with a grey patterned tiled floor and a shower area'},
  { id: 4, category: 'Living room', url: livingroom_walnut, alt: 'Living room with walnut laminate flooring and a grey sofa, accented with an orange and blue painting and wallpaper'},
  { id: 5, category: 'Bedroom', url: bedroom_grey, alt: 'Kids\' bedroom with grey laminate flooring and a white bed, simple and clean design'},
  { id: 6, category: 'Stairs', url: stairs_laminateCarpet, alt: 'Staircase with grey carpet on treads and beige laminate on risers, modern home interior'},
  { id: 7, category: 'Kitchens', url: kitchen_chair, alt: 'Kitchen with grey Bodo 120x60cm floor tiles, featuring an island and sleek black chairs'},
  { id: 8, category: 'Hallways', url: hallway_herringbone, alt: 'Hallway with oak laminate in a herringbone pattern and a freestanding staircase, elegant and stylish'},
  { id: 9, category: 'Living room', url: livingroom_beige, alt: 'Rustic beige laminate flooring in a living room with a rustic grey couch, cozy and inviting'},
  { id: 10, category: 'Bathrooms', url: bathroom_copper, alt: 'Simplistic bathroom with large format 120x60cm porcelain tiles on the floor and small copper metallic effect tiles on the feature wall'},
  { id: 11, category: 'Bedroom', url: bedroom_grey2, alt: 'Bedroom with grey laminate flooring, featuring a white and yellow bed, bright and cheerful'},
  { id: 12, category: 'Stairs', url: stairs_laminate, alt: 'Small staircase with oak beige walnut laminate, compact and modern design'},
  { id: 13, category: 'Kitchens', url: kitchen_bodo, alt: ''},
  { id: 14, category: 'Hallways', url: hallway_oak, alt: ''},
  { id: 15, category: 'Bathrooms', url: bathroom_channel, alt: ''},
  { id: 16, category: 'Living room', url: livingroom_beige2, alt: ''},
  { id: 17, category: 'Bedroom', url: bedroom_walnut, alt: ''},
  { id: 18, category: 'Stairs', url: stairs_herringbone, alt: ''},
  { id: 19, category: 'Kitchens', url: kitchen_splashback, alt: ''},
  { id: 20, category: 'Hallways', url: hallway_greige, alt: ''},
  { id: 21, category: 'Bathrooms', url: bathroom_half, alt: ''},
  { id: 22, category: 'Living room', url: livingroom_deer, alt: ''},
  { id: 23, category: 'Bedroom', url: bedroom_walnut2, alt: ''},
  { id: 24, category: 'Stairs', url: stairs_turn, alt: ''},
  { id: 25, category: 'Kitchens', url: kitchen_camden, alt: ''},
  { id: 26, category: 'Hallways', url: hallway_tile, alt: ''},
  { id: 27, category: 'Bathrooms', url: bathroom_howard, alt: ''},
  { id: 28, category: 'Living room', url: livingroom_walnut2, alt: ''},
  { id: 29, category: 'Bedroom', url: bedroom_bay, alt: ''},
  { id: 30, category: 'Stairs', url: stairs_carpet_laminate2, alt: ''},
  { id: 31, category: 'Stairs', url: alsaherringbone1, alt: ''},
  { id: 32, category: 'Hallways', url: alsaherringbone2, alt: ''},
  { id: 33, category: 'Living room', url: amazing, alt: ''},
  { id: 34, category: 'Bathrooms', url: bathroomwithcirclarmirror, alt: ''}, 
  { id: 35, category: 'Living room', url: crash_beige, alt: ''},
  { id: 36, category: 'Kitchens', url: kitchen_marble, alt: ''}, 
  { id: 37, category: 'Bathrooms', url: Herringbone_tile_shower, alt: ''}, 
  { id: 47, category: 'Hallways', url: image5, alt: '' },
  { id: 48, category: 'Living room', url: image7, alt: '' },
  { id: 49, category: 'Bathrooms', url: IMG_0518, alt: '' },
  { id: 50, category: '', url: IMG_4434, alt: '' },
  { id: 51, category: '', url: IMG_4441, alt: '' },
  { id: 52, category: '', url: IMG_4443, alt: '' },
  { id: 53, category: 'Bedroom', url: IMG_5361, alt: '' },
  { id: 54, category: 'Living room', url: IMG_5364, alt: '' },
  { id: 56, category: '', url: IMG_5588, alt: '' },
  { id: 57, category: 'Hallways', url: IMG_5589, alt: '' },
  { id: 58, category: 'Kitchens', url: kitchen_white_marble, alt: '' },
  { id: 59, category: 'Bathrooms', url: small_bathroom_beige_greige, alt: '' },
  { id: 60, category: 'Bathrooms', url: tilesandwoodfloorstore_2, alt: '' },
  { id: 61, category: 'Bathrooms', url: tilesandwoodfloorstore_3, alt: '' },
  { id: 62, category: 'Living room', url: tilesandwoodfloorstore_4, alt: '' },
  { id: 63, category: 'Living room', url: tilesandwoodfloorstore_9, alt: '' },
  { id: 64, category: '', url: tilesandwoodfloorstore_10, alt: '' },
  { id: 65, category: 'Living room', url: tilesandwoodfloorstore_12, alt: '' },
  { id: 66, category: 'Bathrooms', url: tilesandwoodfloorstore_16, alt: '' },
  { id: 67, category: 'Bathrooms', url: tilesandwoodfloorstore_17, alt: '' },
  { id: 68, category: 'Living room', url: tilesandwoodfloorstore_18, alt: '' },
  { id: 69, category: '', url: tilesandwoodfloorstore_19, alt: '' },
  { id: 70, category: 'Living room', url: tilesandwoodfloorstore_20, alt: '' },
  { id: 71, category: 'Kitchens', url: tilesandwoodfloorstore_22, alt: '' },
  { id: 72, category: 'Living room', url: tilesandwoodfloorstore_24, alt: '' },
  { id: 73, category: 'Bathrooms', url: tilesandwoodfloorstore_25, alt: '' },
  { id: 74, category: 'Kitchens', url: tilesandwoodfloorstore_26, alt: '' },
  { id: 75, category: 'Bathrooms', url: tilesandwoodfloorstore_27, alt: '' },
  { id: 76, category: '', url: tilesandwoodfloorstore_28, alt: '' },
  { id: 77, category: 'Bathrooms', url: tilesandwoodfloorstore_29, alt: '' },
  { id: 78, category: 'Bathrooms', url: tilesandwoodfloorstore_30, alt: '' },
  { id: 80, category: 'Bathrooms', url: tilesandwoodfloorstore_32, alt: '' },
  { id: 81, category: 'Stairs', url: tilesandwoodfloorstore_33, alt: '' },
  { id: 83, category: 'Living room', url: tilesandwoodfloorstore_35, alt: '' },
  { id: 86, category: 'Kitchens', url: tilesandwoodfloorstore_38, alt: '' },
  { id: 87, category: 'Bathrooms', url: tilesandwoodfloorstore_39, alt: '' },
  { id: 88, category: 'Living room', url: tilesandwoodfloorstore_40, alt: '' },
  { id: 89, category: 'Living room', url: tilesandwoodfloorstore_41, alt: '' },
  { id: 90, category: 'Living room', url: tilesandwoodfloorstore_42, alt: '' },
  { id: 91, category: 'Kitchens', url: tilesandwoodfloorstore_44, alt: '' },
  { id: 92, category: 'Kitchens', url: tilesandwoodfloorstore_45, alt: '' },
  { id: 93, category: '', url: tilesandwoodfloorstore_46, alt: '' },
  { id: 95, category: '', url: tilesandwoodfloorstore_48, alt: '' },
  { id: 96, category: '', url: tilesandwoodfloorstore_49, alt: '' },
  { id: 97, category: '', url: tilesandwoodfloorstore_50, alt: '' },
  { id: 98, category: '', url: tilesandwoodfloorstore_52, alt: '' },
  { id: 99, category: 'Kitchens', url: tilesandwoodfloorstore_53, alt: '' },
  { id: 100, category: 'Kitchens', url: tilesandwoodfloorstore_54, alt: '' },
  { id: 102, category: 'Living room', url: tilesandwoodfloorstore_56, alt: '' },
  { id: 103, category: 'Living room', url: tilesandwoodfloorstore_58, alt: '' },
  { id: 105, category: '', url: tilesandwoodfloorstore_60, alt: '' },
  { id: 106, category: 'Living room', url: tilesandwoodfloorstore_61, alt: '' },
  { id: 107, category: 'Kitchens', url: tilesandwoodfloorstore_62, alt: '' },
  { id: 108, category: 'Kitchens', url: tilesandwoodfloorstore_63, alt: '' },
  { id: 109, category: 'Living room', url: tilesandwoodfloorstore_64, alt: '' },
  { id: 110, category: 'Living room', url: tilesandwoodfloorstore_65, alt: '' },
  { id: 111, category: 'Bathrooms', url: tilesandwoodfloorstore_67, alt: '' },
];



const Gallery = () => {
    const [activeCategory, setActiveCategory] = useState('');
  
    const filterPhotos = (category) => {
      setActiveCategory(category);
    };
  
    const filteredPhotos = photoData.filter(photo =>
      activeCategory === '' || photo.category === activeCategory
    );
  
    return (
      <div className='main-container'>
        <h2>Customer Photos</h2>
        <div className="filter-buttons">
          {['Kitchens', 'Hallways', 'Bathrooms', 'Living room', 'Bedroom', 'Stairs'].map(category => (
            <button key={category} onClick={() => filterPhotos(category)}>
              {category}
            </button>
          ))}
          <button onClick={() => filterPhotos('')}>All</button>
        </div>
        <div className='gallery'>
          {filteredPhotos.map(photo => (
            <div key={photo.id} className='photo-item'>
              <img src={photo.url} alt={photo.description} className="gallery-img"/>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Gallery;
